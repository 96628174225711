import { ReuseCardsEvent } from '../../models/reuse-card-events-response';

export const mapEventNameToDisplayName = (eventName: ReuseCardsEvent): string => {
  switch (eventName) {
    case ReuseCardsEvent.CREATE_REUSE_CARD:
      return 'CREATE';
    case ReuseCardsEvent.UPDATE_REUSE_CARD:
      return 'UPDATE';
    default:
      return eventName;
  }
};
