import React, { JSX, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { Tooltip } from '@chakra-ui/react';
import { BooleanValueIcon, Modal, Table } from 'common/components';
import { DEFAULT_PAGINATED_TABLE_LIMIT } from 'common/constants/common';
import { formatDateTime } from 'common/utils';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReuseCardsEvents } from '../../../../services/reuse-cards';
import { ReuseCardEventsResponse } from '../../models/reuse-card-events-response';
import { mapEventNameToDisplayName } from './helpers';

/* eslint-disable react/prop-types */
// https://stackoverflow.com/questions/38684925/react-eslint-error-missing-in-props-validation
// probably there 'react-table' package needs an update
export const ReuseCardsEventsModal = ({
  isModalVisible,
  historyReuseCardId,
  onModalClose,
}: {
  isModalVisible: boolean;
  onModalClose: () => void;
  historyReuseCardId: string;
}): JSX.Element => {
  const translations = useTranslations();

  const [page, setPage] = useState(1);

  const params = {
    offset: page,
    limit: DEFAULT_PAGINATED_TABLE_LIMIT,
  };

  const { isLoading, data } = useReuseCardsEvents(historyReuseCardId, params);

  const columns: Column<ReuseCardEventsResponse>[] = [
    {
      Header: translations('reuse_cards_event_table_type'),
      accessor: (row) => row.name,
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ReuseCardEventsResponse>) => mapEventNameToDisplayName(original.name),
    },
    {
      Header: translations('reuse_cards_event_table_user'),
      accessor: (row) => row.user.userId,
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ReuseCardEventsResponse>) => (
        <Tooltip label={original.user.userId}>
          <span style={{ cursor: 'help' }}>{original.user.userId.substring(0, 8)}...</span>
        </Tooltip>
      ),
    },
    {
      Header: translations('reuse_cards_event_table_is_active'),
      accessor: (row) => row.payload.isActive,
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ReuseCardEventsResponse>) => <BooleanValueIcon value={original.payload.isActive} />,
    },
    {
      Header: translations('reuse_cards_event_table_value'),
      accessor: (row) => row.payload.monetaryValue,
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ReuseCardEventsResponse>) => original.payload.monetaryValue,
    },
    {
      Header: translations('reuse_cards_event_table_currency'),
      accessor: (row) => row.payload.currency,
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ReuseCardEventsResponse>) => original.payload.currency,
    },
    {
      Header: translations('reuse_cards_event_table_borrowing_limit'),
      accessor: (row) => row.payload.borrowingsLimit,
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ReuseCardEventsResponse>) => original.payload.borrowingsLimit,
    },
    {
      Header: translations('reuse_cards_event_table_activation_restaurants'),
      accessor: (row) => row.payload.activationRestaurants?.map((g) => g.name),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ReuseCardEventsResponse>) => (
        <>
          {(original.payload.activationRestaurants || []).map((restaurant, index, self) => (
            <Tooltip label={restaurant.name} key={restaurant.id}>
              <span style={{ cursor: 'help' }}>{index === self.length - 1 ? restaurant.id : `${restaurant.id}, `}</span>
            </Tooltip>
          ))}
        </>
      ),
    },
    {
      Header: translations('reuse_cards_event_table_activation_fb_groups'),
      accessor: (row) => row.payload.activationFnbGroups?.map((g) => g.name),
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ReuseCardEventsResponse>) =>
        (original.payload.activationFnbGroups || []).map((fnb, index, self) => (
          <Tooltip label={fnb.name} key={fnb.id}>
            <span style={{ cursor: 'help' }}>{index === self.length - 1 ? fnb.id : `${fnb.id}, `}</span>
          </Tooltip>
        )),
    },
    {
      Header: translations('reuse_cards_event_table_date'),
      accessor: 'recordCreatedAt' as const,
      Cell: ({
        cell: {
          row: { original },
        },
      }: CellProps<ReuseCardEventsResponse>) => formatDateTime(new Date(original.recordCreatedAt)),
    },
  ];

  return (
    <Modal
      isModalVisible={isModalVisible}
      onModalClose={onModalClose}
      modalHeader={translations('reuse_cards_event_table_header')}
      customModalFooter={null}
      size="6xl"
    >
      <Table<ReuseCardEventsResponse>
        searchEnabled={false}
        data={data?.items}
        columns={columns}
        isLoading={isLoading}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        paginationEnabled={true}
      />
    </Modal>
  );
};
