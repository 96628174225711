export enum ReuseCardsEvent {
  CREATE_REUSE_CARD = 'CREATE_REUSE_CARD',
  UPDATE_REUSE_CARD = 'UPDATE_REUSE_CARD',
}

export type ReuseCardEventsResponsePayload = Partial<{
  userId: string;
  version: string;
  currency: string;
  monetaryValue: number;
  borrowingsLimit: number;
  activationFnbGroups: {
    id: string;
    name: string;
  }[];
  activationRestaurants: {
    id: string;
    name: string;
  }[];
  isActive: boolean;
}>;

export interface ReuseCardEventsResponse {
  name: ReuseCardsEvent;
  recordCreatedAt: string;
  payload: ReuseCardEventsResponsePayload;
  user: { userId: string };
}
