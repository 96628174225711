import React, { useCallback, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';

import { AddEditItemLink, BooleanValueIcon, ErrorMessages, LinkButton, Table } from '../../../../common/components';
import { DEFAULT_PAGINATED_TABLE_LIMIT } from '../../../../common/constants/common';
import { useDebounce } from '../../../../common/hooks/useDebounce';
import { formatDateTime, useExecuteWithConfirmation } from '../../../../common/utils';
import { DEBOUNCE_SEARCH_TIME } from '../../../../config/configConsts';
import { Routes } from '../../../../config/routes';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReuseCardUsers, useVoidReuseCard } from '../../../../services/reuse-cards';
import { ReuseCardUserResponse } from '../../models/reuse-card-user-response';
import { ReuseCardsEventsModal } from './ReuseCardsEventsModal';

export const ReuseCardsTable = () => {
  const translations = useTranslations();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const [historyReuseCardId, setHistoryReuseCardId] = useState<string | null>(null);

  const params = {
    offset: page,
    limit: DEFAULT_PAGINATED_TABLE_LIMIT,
    searchText: debouncedSearch,
  };
  const {
    data: paginatedReuseCards,
    isFetching: isFetchingReuseCards,
    error: reuseCardUsersError,
  } = useReuseCardUsers(params);
  const { mutate: voidReuseCard } = useVoidReuseCard();

  const { executeWithConfirmation: handleVoidCard } = useExecuteWithConfirmation(
    translations('reuse_cards_void_confirm_text'),
    voidReuseCard,
  );

  const handleVoidReuseCardWithConfirmation = useCallback(
    (original: ReuseCardUserResponse) => {
      return () => handleVoidCard({ id: original.id });
    },
    [handleVoidCard],
  );

  const columns = useMemo(() => {
    const openReuseCardEventsModal = (reuseCardId: string) => {
      return () => {
        setHistoryReuseCardId(reuseCardId);
        onModalOpen();
      };
    };

    return [
      {
        Header: translations('reuse_cards_table_user_relevo_id'),
        accessor: 'userRelevoId' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReuseCardUserResponse>) => original.userRelevoId,
      },
      {
        Header: translations('reuse_cards_table_is_active'),
        accessor: 'isActive' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReuseCardUserResponse>) => <BooleanValueIcon value={original.isActive} />,
      },
      {
        Header: translations('reuse_cards_table_created_at'),
        accessor: 'createdAt' as const,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReuseCardUserResponse>) => <>{formatDateTime(new Date(original.createdAt))}</>,
      },
      {
        id: 'actions',
        Header: translations('reuse_cards_table_actions'),
        Cell: ({ row: { original } }: CellProps<ReuseCardUserResponse>) => (
          <Flex>
            <AddEditItemLink to={generatePath(Routes.REUSE_CARDS_UPDATE_PATH, { id: original.id })} m={1} isEdit />
            <LinkButton
              m={1}
              color="orange.500"
              to={generatePath(Routes.REUSE_CARDS_ASSIGNED_ITEMS_PATH, { id: original.id })}
            >
              {translations('reuse_cards_table_assigned_items')}
            </LinkButton>
            <Button m={1} color="orange.500" onClick={openReuseCardEventsModal(original.id)}>
              {translations('reuse_cards_table_event_button')}
            </Button>
            <Button m={1} onClick={handleVoidReuseCardWithConfirmation(original)} isDisabled={!original.isActive}>
              {translations('reuse_cards_table_void_card')}
            </Button>
          </Flex>
        ),
      },
    ];
  }, [translations, handleVoidReuseCardWithConfirmation, onModalOpen]);
  return (
    <>
      <ErrorMessages errors={reuseCardUsersError} />
      <Table<ReuseCardUserResponse>
        data={paginatedReuseCards?.items}
        columns={columns}
        isLoading={isFetchingReuseCards}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedReuseCards?.totalPages}
        paginationEnabled
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('reuse_cards_table_searchbox_placeholder')}
        searchEnabled
      />
      <ReuseCardsEventsModal
        isModalVisible={isModalOpen}
        onModalClose={onModalClose}
        historyReuseCardId={historyReuseCardId as string}
      ></ReuseCardsEventsModal>
    </>
  );
};
